import { useEffect, useState } from "react";
import { useTranslatedRelative, type Locale } from "@i18next/client/utils";

interface Props {
    currentSite: Page.Site;
}

const countryCodes:Record<Locale, Array<string>> = {
  default: ["US"],
  sg: ["SG", "ID", "PH", "BD", "BN", "KH", "MO", "MY", "MN", "MM", "NP", "LK", "VN", "TH"],
  uk: ["GB", "AW", "DK", "SE", "NO", "FI", "IS", "AX", "FO", "GL"],
  au: ["AU", "NZ"],
  fr: ["FR"],
  de: ["DE", "AT"],
  nl: ["NL", "BE", "CW", "SX", "SR"],
  es: ["ES"],
  cn: ["CN"],
  hk: ["HK"],
  id: [],
  jp: [],
  kr: ["KR"],
  tw: ["TW"],
  in: ["IN"]
};

function countryCode2Locale(code: string | undefined) {
  const _code = code?.toUpperCase();
  if(typeof _code === "undefined")
    return _code;
  var locale:Locale | undefined;
  Object.entries(countryCodes).forEach(([l, arr]) => {
    if(arr.includes(_code))
      locale = l as Locale;
  });
  return locale;
}

const UserCountry:React.FC<Props> = ({ currentSite }) => {
    const tr = useTranslatedRelative(currentSite.locale);
    const [html, setHtml] = useState<string>();

    useEffect(() => {
        const storedChoice = JSON.parse(localStorage.getItem("countryModalChoice") || '{}');
        if(storedChoice.choice === "dontShowAgain")
          return;
        //const defaultCountryCode = "nl";
        const defaultCountryCode = window.Osano?.cm?.countryCode;
        const locale = storedChoice.locale as Locale | undefined || countryCode2Locale(defaultCountryCode);
        if (!!locale) {
          if(locale !== currentSite.locale) {
            var url = tr(window.location.pathname, locale);
            try {
              fetch(url, { method: 'HEAD' }).then(response => {
                url = response.ok ? url : tr("", locale);
                if(storedChoice.choice != "dontShowAgain") {
                    fetch(tr(`/partial/UserCountry?url=${encodeURIComponent(url)}&source=${currentSite.locale}`, locale))
                        .then(response => response.text())
                        .then(html => setHtml(html));
                }
              });
            } catch (error) {
              console.error("Error checking URL validity:", error);
            }
          }
        }
    }, []);

    return !!html && <div dangerouslySetInnerHTML={{__html: html}}/>;
};

export default UserCountry;